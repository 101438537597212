.sd-action-bar {
    position: absolute;
    bottom: 20px;
}

.sd-navigation__next-btn {
    display: none;
}

.sd-radio__decorator {
    display: none;
}

.sd-body {
    padding: 0px !important;
}

.sd-item {
    background-color: #fff;
    margin-bottom: 16px;
    border-radius: 8px;
    padding: 0px !important;
    border: 1px solid #ecdccd;
    cursor: pointer;

    background-image: url('data:image/svg+xml,%3Csvg%20width%3D%2217%22%20height%3D%2212%22%20viewBox%3D%220%200%2017%2012%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill%3D%22%23000%22%20fillRule%3D%22evenodd%22%20clipRule%3D%22evenodd%22%20d%3D%22M11.7574%200.696722L16.5303%205.46969C16.8232%205.76259%2016.8232%206.23746%2016.5303%206.53035L11.7574%2011.3033C11.4645%2011.5962%2010.9896%2011.5962%2010.6967%2011.3033C10.4038%2011.0104%2010.4038%2010.5356%2010.6967%2010.2427L14.1893%206.75002L-5.48401e-07%206.75002L-4.17266e-07%205.25002L14.1893%205.25002L10.6967%201.75738C10.4038%201.46449%2010.4038%200.989615%2010.6967%200.696722C10.9896%200.403828%2011.4645%200.403828%2011.7574%200.696722Z%22%2F%3E%3C%2Fsvg%3E');
    background-position: right;
    background-repeat: no-repeat;
    background-position-x: 95%;
}

@media screen and (min-width: 640px) {
    .sd-item:hover {
        background-color: #ecdccd;
        border: 1px solid #be9191;
    }
}

.sd-question__required-text {
    display: none;
}

.sd-selectbase__label {
    width: 100%;
    padding: 16px 40px 16px 24px;
    cursor: pointer;
}

.sd-item span {
    cursor: pointer;
}

.sd-action-bar {
    width: calc(
        100% - (calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px)))) * 2)
    );
}
